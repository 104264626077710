
export default {
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isSoldOut() {
      return this.event?.label?.type === 'soldout'
    },
    img() {
      return this.event.avatar
        ? `${this.$config.CDN_URL}event/${this.event.id}/${this.event.avatar}`
        : ''
    }
  }
}
